import React, { useEffect } from 'react';
import { FiCopy, FiTrash2 } from 'react-icons/fi';
import { BsUpload } from 'react-icons/bs';
import Tooltip from 'react-tooltip-lite';
import FashinzaIconLoader from '../../library/IconLoader';
import { useBoolean } from '../../utils/hooks/useBoolean';
import { copyToClipboard } from '../../utils/utility';
import { IObjectType } from '../../utils/types';
import { FileUploadStatusEnum } from './helperConstant';
import * as Styles from './styles';
import './tooltip.scss';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';

interface IStyleItemProps {
  data: IObjectType;
  handleRetry?(id: number | string): void;
  handleDelete?(id: number | string): void;
  bgColorIndex?: number;
}

function modifyCloudinaryUrl(url: string = '') {
  const str = 'image/upload/';
  if (url.indexOf(str) > -1) {
    return url.replace(str, `${str}h_300,c_scale/`);
  }
  return url;
}

const StyleItem = ({
  data,
  handleRetry,
  handleDelete,
  bgColorIndex,
}: IStyleItemProps) => {
  const imageLink = data.link;
  const [isLinkCopied, linkCopiedActions] = useBoolean();
  let setTimeoutId: ReturnType<typeof setTimeout>;

  function handleClick(type: string) {
    if (type === 'copy_link') {
      linkCopiedActions.on();
      setTimeoutId = setTimeout(() => {
        linkCopiedActions.off();
      }, 3000);
      copyToClipboard(imageLink);
    }
  }

  useEffect(() => {
    return () => {
      if (setTimeoutId) clearTimeout(setTimeoutId);
    };
  }, []);

  return (
    <Styles.StyleItemWrapper>
      {isLinkCopied && <Styles.Pill className="center">Copied</Styles.Pill>}
      {data?.link && data?.status !== FileUploadStatusEnum.Loading && (
        <Styles.StyleImage
          src={modifyCloudinaryUrl(data.link)}
          alt={data.name}
          loading="lazy"
          checkStatus={data.status}
          isLinkCopied={isLinkCopied}
        />
      )}

      {data.status === FileUploadStatusEnum.Loading && (
        <Styles.LoadingContainer bgColorIndex={bgColorIndex ?? 0}>
          <FashinzaIconLoader show />
        </Styles.LoadingContainer>
      )}
      {!isLinkCopied && data.status === FileUploadStatusEnum.Success && (
        <Styles.Overlay />
      )}
      {data.status === FileUploadStatusEnum.Failed && (
        <Styles.FailOverlay className="center">
          <Styles.Button
            onClick={e => {
              if (handleRetry) handleRetry(data.id);
            }}
          >
            <BsUpload className="bs-upload" />
            &nbsp; Retry
          </Styles.Button>
        </Styles.FailOverlay>
      )}
      {data.status === FileUploadStatusEnum.SizeError && (
        <Styles.FailOverlay className="center v-d-flex">
          <Styles.ErrorInfo>
            <p>{data.sizeErrorType}</p>
            <p>{data.correctionMessage}</p>
          </Styles.ErrorInfo>
          <StyledButton
            variant={StyledButtonVariant.Tertiary}
            className="center"
            onClick={e => {
              if (handleDelete) {
                handleDelete(data.id);
              }
            }}
          >
            <FiTrash2 />
            &nbsp; Remove &nbsp;
          </StyledButton>
        </Styles.FailOverlay>
      )}
      {!isLinkCopied && data.status === FileUploadStatusEnum.Success && (
        <Styles.CopyLinkPopUp onClick={() => handleClick('copy_link')}>
          Copy link&nbsp;
          <FiCopy stroke="var(--pacific-blue)" />
        </Styles.CopyLinkPopUp>
      )}
    </Styles.StyleItemWrapper>
  );
};

export default StyleItem;
